import React from "react";
import { graphql } from "gatsby"; // Link
// import Emoji from "a11y-react-emoji/lib/Emoji";

// Layout components
import Layout from "../components/layout/Layout";

import RenderRichContent from "../components/containers/RenderRichContent";
import { superEvent } from "../utils/superEvent";

// Modules:
import HomePoster from "../components/layout/HomePoster";

// Primitives
import EmojiIcon from "../components/primitives/EmojiIcon";

const Home = ({ data }) => {
  const { metaInfo, _rawContent } = data?.sanityPages || {};
  // const { headerText1 = "", headerText2 = "" } =
  //   data.allSanitySiteSettings?.nodes[0] || {}

  const metaStructure = {
    title: metaInfo?.header,
    someTitle: metaInfo?.someHeader,
    description: metaInfo?.description,
    image: metaInfo?.image,
    imageAlt: metaInfo?.imageAlt,
  };

  return (
    <Layout metaInfo={metaStructure} pageSchemas={superEvent}>
      {/* The first thing you see on the site */}
      <HomePoster />

      <div className="flex flex-col items-center justify-center">
        {/* Emojis for reference */}

        {/* <div className="flex justify-center font-sans-symbols text-2xl">
          <EmojiIcon name="coffee" animated />
          <EmojiIcon name="smile" animated />
          <EmojiIcon name="wonder" animated />
          <EmojiIcon name="leadership" animated />
          <EmojiIcon name="clap" animated />
          <EmojiIcon name="businessDev" animated />
          <EmojiIcon name="flagUk" animated />
          <EmojiIcon name="flagNo" animated />
          <EmojiIcon name="important" animated />
          <EmojiIcon name="livestream" animated />
          <EmojiIcon name="party" animated />
          <EmojiIcon name="workshop" animated />
          <EmojiIcon name="sustainability" animated />
          <EmojiIcon name="stage" animated />
          <EmojiIcon name="question" animated />
          <EmojiIcon name="presentation" animated />
          <EmojiIcon name="content" animated />
          <EmojiIcon name="design" animated />
          <EmojiIcon name="tech" animated />
          <EmojiIcon name="productService" animated />
        </div> */}

        <p
          className="
            md:w-2/3
            mb-12
            text-xl uppercase font-bold text-center
          "
        >
          Gathering <EmojiIcon name="lightbulb" animated /> smart people{" "}
          <EmojiIcon name="smile" animated /> to get even better at building{" "}
          <EmojiIcon name="businessDev" animated /> products{" "}
          <EmojiIcon name="productService" animated /> and services.{" "}
        </p>

        <RenderRichContent content={_rawContent} />
      </div>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query {
    allSanitySiteSettings {
      nodes {
        headerText1
        headerText2
      }
    }
    sanityPages(slug: { current: { eq: "index" } }) {
      id
      pageTitle
      _rawContent(resolveReferences: { maxDepth: 10 })
      metaInfo {
        description
        header
        someHeader
        imageAlt
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;
